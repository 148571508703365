/*
  ERROR PAGE TEMPLATE
  A template to display 404 pages
*/

import React from 'react';
import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

import PageLayout from '@base/layout/page-layout';
import Button from '@base/parts/button';

import { getGeneralData } from '@api/general-data';
import { getModalBuy } from '@elements/modal-buy';

function ErrorPage({ pageContext }) {
  const browser = typeof window !== 'undefined' && window;
  if (!pageContext || !browser) {
    return null;
  }

  const data = {
    pageData: {
      ...pageContext.content,
    },
  };

  // Setup Page specific data
  const generalData = getGeneralData(pageContext, data);
  const minifiedPageContext = generalData.pageContext;
  const { globalData } = generalData;
  const { modalData } = generalData;
  const title = data?.pageData?.title;
  const parentPage = null;
  const disablePageBreadcrumbs = true;
  const theme = pageContext.siteTheme;
  const contentStyle = theme?.content?.ErrorPage || null;

  // Use the project's own "Buy Game" Modal Component
  const modalTitle = minifiedPageContext?.buyModalTitle;
  const modalSubTitle = minifiedPageContext?.buyModalSubTitle;

  const customModalBuy = getModalBuy();

  return (
    <PageLayout
      pageContext={minifiedPageContext}
      modalData={modalData}
      breadcrumbs={{
        enableGlobalBreadcrumbs: true,
        disablePageBreadcrumbs,
        postTitle: title,
        postParent: [parentPage],
        strings: { back: globalData.stringsActions.back, home: 'Home' },
      }}
      modalBuyComponent={() => customModalBuy(pageContext, modalData, modalTitle, modalSubTitle)}
    >
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className='relative flex min-h-screen-2/3 flex-col items-center justify-center overflow-hidden px-8 py-32'
      >
        <h1 className={contentStyle !== null ? contentStyle.title : ''}>
          <span>{title || 'Not found'}</span>
        </h1>
        <p className={contentStyle !== null ? `h3 ${contentStyle.text}` : 'h3'}>
          {data?.pageData?.text || 'The page you are looking for does not exists or has been moved.'}
        </p>
        <Button
          link={pageContext.locale !== 'en' ? `/${pageContext.locale}` : '/'}
          trigger='internalLink'
          size='lg'
          pageContext={pageContext}
          className='mt-8 lg:mt-16'
        >
          <span>{data?.pageData?.button || 'Back to home page'}</span>
        </Button>
        <StaticImage
          src='../assets/img/backgrounds/modal-background.jpg'
          alt=''
          className='gatsby-absolute pointer-events-none left-0 top-0 z-under h-full w-full'
          placeholder='#220422'
          loading='lazy'
        />
      </motion.section>
    </PageLayout>
  );
}

export default ErrorPage;

export { Head } from '@base/layout/head';
